import '../styles/globals.css';
import '../styles/bootstrap.scss';
import { Lato } from 'next/font/google';

import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../styles/globalstyles';
import { AppContextProvider } from '../context/AppContext';
import CookieConsentComponent from '../components/CookieConsentComponent';
import CookiesServerSide from 'cookies';
import GoogleTagManagerComponent from '../components/ConsentRequiredComponents/GoogleTagManagerComponent';
import Head from 'next/head';

const lato = Lato({
  weight: ['100', '300', '400', '700', '900'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
});

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      <ThemeProvider theme={{}}>
        <AppContextProvider cookieConsent={pageProps.cookieConsent} env={pageProps.env}>
          <CookieConsentComponent />
          <GlobalStyle fontFamily={lato.style.fontFamily} />
          <Component {...pageProps} />

          <GoogleTagManagerComponent />
        </AppContextProvider>
      </ThemeProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const cookies = new CookiesServerSide(appContext.ctx.req, appContext.ctx.res);

  // pre-read the cookie consent to already pass a cookie value to prevent unnecessary frontend cookie display
  let cookieConsent;
  try {
    const valueRaw = decodeURIComponent(cookies.get('cookie-consent'));
    if (valueRaw) {
      cookieConsent = JSON.parse(valueRaw);
    }
  } catch (e) {
    cookieConsent = undefined;
  }

  const { zip } = appContext.ctx.query;
  return {
    pageProps: {
      zip: zip || '',
      env: process.env.APP_ENV,
      cookieConsent,
    },
  };
};

export default MyApp;
