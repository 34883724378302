import { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const appContextDefaultValues = {
  cookieConsent: undefined,
  setCookieConsent: () => {},
};
const AppContext = createContext(appContextDefaultValues);

export function useAppContext() {
  return useContext(AppContext);
}

export const COOKIE_CONSENT_NAME = 'cookie-consent';
export function AppContextProvider({ cookieConsent, env, children }) {
  const getInitialCookieConsent = () => {
    const cookieValue = Cookies.get(COOKIE_CONSENT_NAME);
    if (cookieValue) return JSON.parse(cookieValue);
    return undefined;
  };

  const setCookieConsent = (cookieValue, expires = 365) => {
    Cookies.set(COOKIE_CONSENT_NAME, JSON.stringify(cookieValue), {
      expires,
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    });
    setState((state) => ({ ...state, cookieConsent: cookieValue }));
  };
  const removeCookieConsent = () => {
    Cookies.remove(COOKIE_CONSENT_NAME);
    setState((state) => ({ ...state, cookieConsent: undefined }));
  };

  const initState = {
    cookieConsent: cookieConsent,
    setCookieConsent,
    removeCookieConsent,
    env,
  };
  const [state, setState] = useState(initState);

  useEffect(() => {
    setState((state) => {
      return {
        ...state,
        cookieConsent: getInitialCookieConsent(),
      };
    });
  }, []);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
}

export default AppContext;
