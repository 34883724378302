import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html, body {
        font-family: ${(props) => props.fontFamily};
    }

    /* font styling */
    h2 {
        font-weight: 700;
        font-size: 27px;
        letter-spacing: 1.2px;
        margin-bottom: 15px;
    }
    h3 {
        color: var(--bs-primary);
        font-weight: 700;
        font-size: 15px;
        letter-spacing: 0.67px;
        margin-bottom: 15px;
    }
    p {
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.67px;
        margin-bottom: 15px;
    }

    a {
        color: inherit;
        text-decoration: inherit;

        &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }

    .btn-link {
        color: inherit;
        font-weight: inherit;
        text-decoration: inherit;
        padding: 0;
        font-size: inherit;
        letter-spacing: inherit;
        box-shadow: none!important;

        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }

    /* Custom Floating Input with custom height amk */
    .form-control.size-md {
        height: 50px;
    }
    .form-floating.size-md > label {
        line-height: 50px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0.75rem
    }
    .form-floating.size-md > .form-control.size-md:focus ~ label, .form-floating.size-md > .form-control.size-md:not(:placeholder-shown) ~ label, .form-floating.size-md > .form-select.size-md ~ label {
        transform: scale(0.75) translateY(-0.5rem) translateX(0.33rem);
    }
    .form-floating.size-md > .form-control.size-md:focus, .form-floating.size-md > .form-control.size-md:not(:placeholder-shown) {
        padding-top: 1.5rem;
    }
    .form-floating > label {
        white-space: nowrap;
    }

    /* validation */
    .form-control.is-invalid + label {
        color: var(--bs-warning);
    }
    .form-control.is-valid {
        border-color: #97cf97;

        background-position: right calc(0.375em + 0.15rem) top 1.5rem;
        background-size: calc(0.75em + 0.2rem) calc(0.75em + 0.2rem);
    }
    .form-control.is-invalid {
        border-color: #ec971f;
    }
`;

export default GlobalStyle;
