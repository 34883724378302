import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalDialog, Button } from 'react-bootstrap';
import CookieSvg from '../public/svg/cookie.icon.svg';
import AppContext from '../context/AppContext';

const FaModal = styled(ModalDialog)`
  &.modal-dialog {
    margin-top: 70px;

    @media (min-width: 992px) {
      margin-top: 120px;
    }
  }

  .modal-content {
    border: none;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
    flex-flow: column;
    align-items: flex-start;
  }

  .modal-footer {
    border-top: none;
    padding-top: 0px;

    & .btn-lg {
      font-size: 17px;
      line-height: 30px;
    }
  }

  .modal-body {
    padding-bottom: 0px;
    h5 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
      line-height: 15px;
      font-weight: 300;
    }
  }
`;

const FaModalTitle = styled.h4`
  font-size: 17px;
  font-weight: 400;
  margin: 0;
`;
const CookieIconWrapper = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;

  margin-left: 30px;
`;

const Divider = styled.div`
  height: 2px;
  width: 135px;
  background: var(--bs-primary);
  margin-top: 10px;
  margin-left: -1rem;
`;

function CookieConsentComponent(props) {
  const { cookieConsent, setCookieConsent } = useContext(AppContext);

  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    if (cookieConsent) {
      setShowCookieBanner(false);
    } else {
      setShowCookieBanner(true);
    }
  }, [cookieConsent]);

  const clickAcceptAll = () => {
    setCookieConsent({ chosen: true, acceptAll: true });
  };

  const clickAcceptEssential = () => {
    setCookieConsent({ chosen: true, acceptAll: false }, 30);
  };

  return (
    <Modal show={showCookieBanner} onHide={() => {}} dialogAs={FaModal}>
      <Modal.Header>
        <Modal.Title as={FaModalTitle}>
          Cookies
          <CookieIconWrapper>
            <CookieSvg />
          </CookieIconWrapper>
        </Modal.Title>
        <Divider>&nbsp;</Divider>
      </Modal.Header>
      <Modal.Body>
        <p>
          Wir verwenden <strong>Cookies</strong>, um Inhalte zu personalisieren und die Zugriffe auf
          unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung
          unserer Website an unsere Partner für Analysen weiter.
        </p>
        <p>
          Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die
          Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt
          haben. Mit Klick auf &quot;Alles klar!&quot; stimmen Sie der Nutzung dieser Dienste
          vollumfänglich zu.
        </p>
        <p>
          Unter <strong>Cookie Einstellungen</strong> oder innerhalb der{' '}
          <strong>Datenschutzerklärung</strong> kannst Du Deine Cookies selbständig konfigurieren.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-grid gap-2" style={{ flexGrow: 1 }}>
          <Button type="submit" variant="primary" size="lg" onClick={clickAcceptAll}>
            <div style={{ margin: '0 auto' }}>Alles klar!</div>
          </Button>
          <Button
            type="button"
            variant="outline-secondary"
            size="sm"
            onClick={clickAcceptEssential}
          >
            <div>Nur Essentielle</div>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CookieConsentComponent;
