import { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import AppContext from '../../context/AppContext';
import { useRef } from 'react';

function GoogleTagManagerComponent() {
  const [renderComponent, setRenderComponent] = useState(false);
  const { cookieConsent, env } = useContext(AppContext);
  const router = useRouter();

  const isRendered = useRef(false);

  useEffect(() => {
    const tagManagerArgs = () => {
      switch (env) {
        case 'production':
          return {
            gtmId: 'GTM-WDKZL6B',
            auth: '-IhA4arrftKM1zbttziRyg',
            preview: 'env-1',
          };
        case 'staging':
          return {
            gtmId: 'GTM-WDKZL6B',
            auth: 'LxO2jF4KODTMxsGLk5UbmQ',
            preview: 'env-4',
          };
        case 'development':
        default:
          return {
            gtmId: 'GTM-WDKZL6B',
            auth: 'KRpqI0s_xe1Irz_J7VJCog',
            preview: 'env-3',
          };
      }
    };

    if (cookieConsent && renderComponent !== cookieConsent.acceptAll) {
      if (cookieConsent.acceptAll) {
        if (!isRendered.current) {
          setRenderComponent(true);
          TagManager.initialize(tagManagerArgs());
          isRendered.current = true;
        }
      } else {
        setRenderComponent(false);
        // reload when switch from accept to decline, so tags are getting cleared
        router.reload(window.location.pathname);
      }
    }
  }, [cookieConsent, env, router, renderComponent]);

  return <></>;
}

export default GoogleTagManagerComponent;
